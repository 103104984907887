import { Box, Divider, Heading } from '@chakra-ui/react';
import React from 'react';
import LoadingPlaceholder from '../../components/loading/LoadingPlaceholder';
import api from '../../api';
import { User } from '../../user';
import Error from '../error/Error';
import UserGifts from '../gifts/UserGifts';
import GiftCatalog from '../gifts/GiftCatalog';

function DefaultApp() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [users, setUsers] = React.useState<User[] | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const getUsersFromApi = async () => {
    setLoading(true);
    const result = await api.getAllUsers();
    if (result.success) {
      setUsers(result.users);
    } else {
      setError(result.message || 'Failed to load user data');
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getUsersFromApi().catch(() => {
      setError('Failed to load user data');
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <Heading as="h2" size="xl">
          Christmas Gifts 2024
        </Heading>
      </Box>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        {loading ? (
          <LoadingPlaceholder />
        ) : users ? (
          <>
            <UserGifts users={users} />
            <Divider mt={4} mb={4} />
            <Heading as="h2" size="lg">
              Catalog
            </Heading>
            <Divider mt={4} mb={4} />
            <GiftCatalog />
          </>
        ) : error ? (
          <Error message="Could not load users" description={error} />
        ) : (
          <Error
            message="Unexpected Error"
            description="Try reloading the app"
          />
        )}
      </Box>
    </>
  );
}

export default DefaultApp;
