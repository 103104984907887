import React, { useContext, useEffect } from 'react';
import { User, UserContext, UserState } from '../../user';
import { CredentialsContext } from '../../credentials';
import api from '../../api';
import ErrorDialog from '../modals/ErrorDialog';
import LoadingPlaceholder from '../loading/LoadingPlaceholder';

type Props = {
  children: React.ReactNode;
};

const UserContextProvider = ({ children }: Props) => {
  const credentials = useContext(CredentialsContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [reloadCount, setReloadCount] = React.useState<number>(0);

  const getUserFromApi = async () => {
    setLoading(true);
    const result = await api.getAllUsers(credentials);
    if (result.success) {
      setUser(result.users.find((u) => u.username === credentials.username));
    } else {
      setError('Failed to load user data');
    }
    setLoading(false);
  };

  const getUserFromApiSync = () => {
    getUserFromApi().catch(() => {
      setError('Failed to load user data');
      setLoading(false);
    });
  };

  const setUserStateOnApi = async (state: Partial<UserState>) => {
    setSubmitting(true);
    const existingState = user?.state || {};
    const newState = { ...existingState, ...state };
    const result = await api.setUserState(credentials, newState);
    if (result.success) {
      setUser({ ...user!, state: newState });
    } else {
      setError('Failed to update user');
    }
    setSubmitting(false);
  };

  const setUserStateOnApiSync = (state: Partial<UserState>) => {
    setUserStateOnApi(state).catch(() => {
      setError('Failed to update user');
      setLoading(false);
    });
  };

  useEffect(() => getUserFromApiSync(), [reloadCount]);

  const reload = () => {
    setError(undefined);
    setReloadCount(reloadCount + 1);
  };

  return loading || submitting ? (
    <LoadingPlaceholder />
  ) : !!user ? (
    <UserContext.Provider value={{ user, updateState: setUserStateOnApiSync }}>
      {children}
    </UserContext.Provider>
  ) : (
    <ErrorDialog
      isOpen={true}
      onClose={reload}
      header="Error"
      body={error || 'Unexpected Error'}
      buttonText="Reload"
    />
  );
};

export default UserContextProvider;
