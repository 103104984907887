import React, { useContext } from 'react';
import { Box, Card, CardBody, CardHeader, Heading, SimpleGrid, Image } from '@chakra-ui/react';
import { VariantContext } from '../../variants';
import Countdown, { CountdownRenderProps } from 'react-countdown';

function CountdownHome({ days, hours, minutes, seconds, completed }: CountdownRenderProps) {
  return (
    <>
      <Box w="100%" p={5}>
        Check back soon...
      </Box>
      <SimpleGrid columns={4} spacing={4}>
        <Card textAlign="center">
          <CardHeader>
            <Heading size='xl'>{days}</Heading>
          </CardHeader>
          <CardBody>days</CardBody>
        </Card>
        <Card textAlign="center">
          <CardHeader>
            <Heading size='xl'>{hours}</Heading>
          </CardHeader>
          <CardBody>hrs</CardBody>
        </Card>
        <Card textAlign="center">
          <CardHeader>
            <Heading size='xl'>{minutes}</Heading>
          </CardHeader>
          <CardBody>mins</CardBody>
        </Card>
        <Card textAlign="center">
          <CardHeader>
            <Heading size='xl'>{seconds}</Heading>
          </CardHeader>
          <CardBody>secs</CardBody>
        </Card>
      </SimpleGrid>
    </>
  );
}

function CountdownApp() {
  const variant = useContext(VariantContext);
  return (
    <>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <Heading as="h2" size="xl">
          Merry Christmas {variant.name}!
        </Heading>
      </Box>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <Countdown date={new Date('2024-12-25T00:00:00Z')} renderer={CountdownHome} />
      </Box>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <Image objectFit="cover" maxW="100%" src={variant.coverImageUri} />
      </Box>
    </>
  );
}

export default CountdownApp;