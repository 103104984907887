import React from 'react';
import {
  Variant,
  VariantContext,
  VariantType,
  getVariant,
} from '../../variants';
import App from '../../pages/app/App';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';
import DefaultApp from '../../pages/app/DefaultApp';
import CountdownApp from '../../pages/app/CountdownApp';

const extractVariantType = (hostname: string): VariantType | undefined => {
  const match = hostname.match(
    /^([^.]+\.)*(?<variant>michelle|fletcher|piper|lucas)(\.[^.]+)*$/
  );
  return match?.groups?.variant as VariantType | undefined;
};

const determineVariant = (hostname: string): Variant | undefined => {
  const variantType = extractVariantType(hostname);
  return variantType ? getVariant(variantType) : undefined;
};

function Root() {
  const variant = determineVariant(window.location.hostname);
  const shouldCountdown = Date.now() < new Date('2024-12-25T00:00:00Z').getTime();

  return (
    <ChakraProvider theme={theme}>
      {variant ? (
        <VariantContext.Provider value={variant}>
          {shouldCountdown ? (
            <CountdownApp />
          ) : (
            <App />
          )}
        </VariantContext.Provider>
      ) : (
        <DefaultApp />
      )}
    </ChakraProvider>
  );
}

export default Root;
