import React from 'react';
import girlsImage from './assets/images/girls-2023.jpg';
import boysImage from './assets/images/boys-2023.jpg';

export enum VariantType {
  Michelle = 'michelle',
  Fletcher = 'fletcher',
  Piper = 'piper',
  Lucas = 'lucas',
}

enum SiblingType {
  Sister = 'sister',
  Brother = 'brother',
}

export interface Variant {
  readonly type: VariantType;
  readonly name: string;
  readonly siblingType: SiblingType;
  readonly coverImageUri: string;
}

const michelle: Variant = {
  type: VariantType.Michelle,
  name: 'Michelle',
  siblingType: SiblingType.Brother,
  coverImageUri: girlsImage,
};

const fletcher: Variant = {
  type: VariantType.Fletcher,
  name: 'Fletcher',
  siblingType: SiblingType.Sister,
  coverImageUri: boysImage,
};

const piper: Variant = {
  type: VariantType.Piper,
  name: 'Piper',
  siblingType: SiblingType.Brother,
  coverImageUri: girlsImage,
};

const lucas: Variant = {
  type: VariantType.Lucas,
  name: 'Lucas',
  siblingType: SiblingType.Sister,
  coverImageUri: boysImage,
};

export const getVariant = (type: VariantType): Variant => {
  switch (type) {
    case VariantType.Michelle:
      return michelle;
    case VariantType.Fletcher:
      return fletcher;
    case VariantType.Piper:
      return piper;
    case VariantType.Lucas:
      return lucas;
  }
};

export const VariantContext = React.createContext<Variant>(michelle);
