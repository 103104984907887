import React, { useContext, useState } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  InputRightElement,
  InputGroup,
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Image,
} from '@chakra-ui/react';
import { VariantContext } from '../../variants';
import api from '../../api';

type Props = {
  onSuccess: (credentials: { username: string; password: string }) => void;
};

function PasswordForm({ onSuccess }: Props) {
  const variant = useContext(VariantContext);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<{ message?: string } | null>(null);
  const [errorCount, setErrorCount] = useState(0);

  const login = async (password: string) => {
    setSubmitting(true);
    const credentials = { username: variant.type, password };
    const response = await api.authUser(credentials);
    if (response.success) {
      setErrorCount(0);
      onSuccess(credentials);
    } else {
      setPassword('');
      setErrorCount(errorCount + 1);
      setError(response);
    }
    setSubmitting(false);
  };

  const showHint = errorCount >= 3 && !error;
  const showBigHint = errorCount >= 5;
  const dismissError = () => setError(null);

  return (
    <>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <Heading as="h2" size="xl">
          {variant.name} Login
        </Heading>
        <Alert mt={4} hidden={!showBigHint} status="info">
          <AlertIcon />
          Make sure to complete both puzzles to decode the correct password!
        </Alert>
        <FormControl mt={4} isInvalid={!!error}>
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={dismissError}
              disabled={submitting}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={toggleShowPassword}>
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormHelperText hidden={!showHint}>
            Complete the puzzle to find the password on the back!
          </FormHelperText>
          <FormErrorMessage hidden={!error}>
            {error?.message ?? 'An unknown error occurred.'}
          </FormErrorMessage>
        </FormControl>
        <Button mt={4} onClick={() => login(password)} disabled={submitting}>
          Login
        </Button>
      </Box>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <Image objectFit="cover" maxW="100%" src={variant.coverImageUri} />
      </Box>
    </>
  );
}

export default PasswordForm;
