import balletShoesImage from './assets/images/play-ballet-shoes.webp';
import comeAliveImage from './assets/images/circus-come-alive.jpg';
import calamityJaneImage from './assets/images/musical-calamity-jane.jpg';
import hairsprayImage from './assets/images/musical-hairspray.webp';
import starTrekImage from './assets/images/vr-star-trek.avif';
import deadwoodValleyImage from './assets/images/vr-deadwood-valley.avif';
import meanGirlsImage from './assets/images/musical-mean-girls.jpg';
import cluelessImage from './assets/images/musical-clueless.webp';
import starlightExpressImage from './assets/images/musical-starlight-express.webp';
import lionKingImage from './assets/images/musical-lion-king.jpeg';
import kartingImage from './assets/images/activity-karting.avif';
import oldTraffordImage from './assets/images/tour-old-trafford.jpg';
import oliverImage from './assets/images/musical-oliver.avif';
import lightningThiefImage from './assets/images/musical-lightning-thief.jpg';
import backToTheFutureImage from './assets/images/musical-back-to-the-future.jpeg';
import josephImage from './assets/images/musical-joseph.jpg';

export interface Gift {
  readonly id: string;
  readonly name: string;
  readonly imageUri: string;
  readonly description: string;
  readonly dates?: string;
  readonly location: string;
  readonly mapsUri: string;
  readonly websiteUri: string;
  readonly youtubeUri?: string;
}
export const gifts: Gift[] = [
  {
    id: 'play-ballet-shoes',
    name: 'Ballet Shoes',
    imageUri: balletShoesImage,
    description: 'Play about three adopted sisters – Pauline, Petrova and Posy – learning who they are and what they want to be.',
    dates: 'Until 22 February 2025',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/cnbxDkAFUhx5iex48',
    websiteUri: 'https://www.nationaltheatre.org.uk/productions/ballet-shoes/',
    youtubeUri: 'https://youtu.be/1EuAgTy7KkM',
  },
  {
    id: 'circus-come-alive',
    name: 'Come Alive!',
    imageUri: comeAliveImage,
    description: 'The Greatest Showman Circus Spectacular!',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/7bqmyeEy9XY62iXc7',
    websiteUri: 'https://comealiveshow.com/',
    youtubeUri: 'https://youtu.be/slsCf17SZsI',
  },
  {
    id: 'musical-calamity-jane',
    name: 'Calamity Jane',
    imageUri: calamityJaneImage,
    description: 'Musical about the fearless, gun slingin\' Calamity Jane – biggest mouth in Dakota territory and always up for a fight.',
    dates: '28 January - 1 February 2025',
    location: 'Southend',
    mapsUri: 'https://maps.app.goo.gl/ZBP65Bop4eEaXUKn6',
    websiteUri: 'https://calamityjanemusical.com/',
    youtubeUri: 'https://youtu.be/dJHHl2pz1AM',
  },
  {
    id: 'musical-hairspray',
    name: 'Hairspray',
    imageUri: hairsprayImage,
    description: 'Musical about big hearted Tracy Turnblad and her new friends as they strive to change the world.',
    dates: '14 - 19 April 2025',
    location: 'Sunderland',
    mapsUri: 'https://maps.app.goo.gl/zKdMNfoLhpip4phx7',
    websiteUri: 'https://www.hairsprayuktour.com/',
    youtubeUri: 'https://youtu.be/1sp-WGCwH1g',
  },
  {
    id: 'vr-star-trek',
    name: 'Star Trek: Discovery Away Mission',
    imageUri: starTrekImage,
    description: 'Star Trek themed virtual reality experience.',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/9fDZ2tK2tf3EvLr87',
    websiteUri: 'https://sandboxvr.com/london/experience/startrek',
    youtubeUri: 'https://youtu.be/s5E0t96T36k',
  },
  {
    id: 'vr-deadwood-valley',
    name: 'Deadwood Valley',
    imageUri: deadwoodValleyImage,
    description: 'Adrenaline-pumping zombie thrill-ride virtual reality experience.',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/9fDZ2tK2tf3EvLr87',
    websiteUri: 'https://sandboxvr.com/london/experience/deadwoodvalley',
    youtubeUri: 'https://youtu.be/7Cd6upCA_CQ',
  },
  {
    id: 'musical-mean-girls',
    name: 'Mean Girls',
    imageUri: meanGirlsImage,
    description: 'Musical based on the 2004 film.',
    dates: 'Until 8 June 2025',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/EdxZuRS3mNuM9Kyo7',
    websiteUri: 'https://london.meangirlsmusical.com/',
    youtubeUri: 'https://youtu.be/MLMXt6Vx7tk',
  },
  {
    id: 'musical-clueless',
    name: 'Clueless',
    imageUri: cluelessImage,
    description: 'Musical comedy based on the 1995 film - a modern spin on Jane Austen\'s Emma',
    dates: 'From 15 February 2025',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/LwD6R9vAkohnHimT8',
    websiteUri: 'https://cluelessonstage.com/',
    youtubeUri: 'https://youtu.be/wj58rSiZiws',
  },
  {
    id: 'musical-starlight-express',
    name: 'Starlight Express',
    imageUri: starlightExpressImage,
    description: 'Musical about a child’s train set which magically comes to life and the engines race to become the fastest in the world.',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/hpGSPDcUVmQreE5Q7',
    websiteUri: 'https://www.starlightexpresslondon.com/',
    youtubeUri: 'https://youtu.be/mG91uquhoxE?feature=shared',
  },
  {
    id: 'musical-lion-king',
    name: 'The Lion King',
    imageUri: lionKingImage,
    description: 'Musical based on the 1994 film.',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/4H1n9kCyRx7MuAhu7',
    websiteUri: 'https://www.thelionking.co.uk/',
    youtubeUri: 'https://youtu.be/o_VPnwAsQDg',
  },
  {
    id: 'activity-karting',
    name: 'Go Karting',
    imageUri: kartingImage,
    description: 'Go Karting either in Newcastle or Basildon.',
    location: 'Newcastle / Essex',
    mapsUri: 'https://maps.app.goo.gl/p5Bn2g9hvdpDFgzSA',
    websiteUri: 'https://www.team-sport.co.uk/go-kart-tracks/basildon',
  },
  {
    id: 'tour-old-trafford',
    name: 'Old Trafford Tour & Museum',
    imageUri: oldTraffordImage,
    description: 'Explore Old Trafford, walking in the footsteps of greatness... apparently.',
    location: 'Manchester',
    mapsUri: 'https://maps.app.goo.gl/nizgqK3AHCoEsMbA8',
    websiteUri: 'https://www.manutd.com/en/Visit-Old-Trafford/Museum-Stadium-Tours',
  },
  {
    id: 'musical-oliver',
    name: 'Oliver!',
    imageUri: oliverImage,
    description: 'Musical based on the Charles Dickens novel.',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/pafEAskMarPtpaZE9',
    websiteUri: 'https://oliverthemusical.com/',
    youtubeUri: 'https://youtu.be/1khjWo2hi74?feature=shared',
  },
  {
    id: 'musical-lightning-thief',
    name: 'The Lightning Thief',
    imageUri: lightningThiefImage,
    description: 'Musical based on the Percy Jackson book series.',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/uqSwhSC3xurLCUrm8',
    websiteUri: 'https://theotherpalace.co.uk/the-lightning-thief-musical/',
    youtubeUri: 'https://youtu.be/PoNYz7_nfLk?feature=shared',
  },
  {
    id: 'back-to-the-future',
    name: 'Back to the Future',
    imageUri: backToTheFutureImage,
    description: 'Musical based on the 1985 film.',
    location: 'London',
    mapsUri: 'https://maps.app.goo.gl/9AKGbJhdTEoCJe4aA',
    websiteUri: 'https://www.backtothefuturemusical.com/london/',
    youtubeUri: 'https://youtu.be/rwU_HLUKv98',
  },
  {
    id: 'musical-joseph',
    name: 'Joseph and the Amazing Technicolor Dreamcoat',
    imageUri: josephImage,
    description: 'Musical about Joseph and his coat of many colours!',
    dates: '14 - 19 January 2025',
    location: 'Sunderland',
    mapsUri: 'https://maps.app.goo.gl/zKdMNfoLhpip4phx7',
    websiteUri: 'https://uk.josephthemusical.com/',
    youtubeUri: 'https://youtu.be/SCf4PvCz1r0',
  },
];

export const getGift = (id: string): Gift | undefined =>
  gifts.find((gift) => gift.id === id);
